<template>
 
 <div class="mediakit">


  
  <div class="box" refc="sitewide">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_sw_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_sw_text1") }}<br/>
          - {{ $t("partner_landing_sw_text2") }}<br/>
          - {{ $t("partner_landing_sw_text3") }}<br/>
          - {{ $t("partner_landing_sw_text4") }}<br/> 
          - Add various designs<br/> 
        </div>
        <img src="/partner/mediakit/b1.gif" alt="" class="exp1">
        <img src="/partner/mediakit/b2.gif" alt="" class="exp2">
        <div class="pscr">
          {{ $t("partner_landing_sw_info") }}
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b3_z.jpg">
            <img src="/partner/mediakit/b3.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <nuxt-link target="_blank" to="/diaries/115-economical-cultivation-on-mapito">{{ $t("partner_landing_report_link") }}</nuxt-link>
          </div>
      </div>

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b4_z.jpg">
            <img src="/partner/mediakit/b4.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <nuxt-link target="_blank" to="/seedbank">{{ $t("partner_landing_seedbank_link") }}</nuxt-link>
          </div>
      </div>

    </div>

  </div>







  <div class="box" refc="custom_contest">

    <div class="lf"> 
        <h2 class="bttl">{{ $t("partner_landing_cc_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_cc_text1") }}<br/>
          - Get content for Social Media <br/>
          - {{ $t("partner_landing_cc_text2") }}<br/> 
          - {{ $t("partner_landing_cc_text4") }}<br/>
          - {{ $t("partner_landing_cc_text5") }}<br/> 
        </div>
        <div class="custom_checks">
          <div class="ck">
            <i class="icon check circle"></i>
            <span>{{ $t("partner_landing_cc_subtext1") }}</span>
          </div>
          <div class="ck">
            <i class="icon check circle"></i>
            <span>{{ $t("partner_landing_cc_subtext2") }}</span>
          </div>
          <div class="ck">
            <i class="icon check circle"></i>
            <span>{{ $t("partner_landing_cc_subtext3") }}</span>
          </div>
          <div class="ck">
            <i class="icon check circle"></i>
            <span>{{ $t("partner_landing_cc_subtext4") }}</span>
          </div>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b5_z.jpg">
            <img src="/partner/mediakit/b5.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <nuxt-link target="_blank" to="/giveaways">{{ $t("partner_landing_giveaway_link") }}</nuxt-link>
          </div>
      </div>

    </div>

  </div>





  





  <div class="box" refc="email_newsletter">

    <div class="lf">
        <h2 class="bttl">Email {{ $t("partner_landing_nl_title") }}</h2>
        <div class="desc">
          - Promote your brand<br/>
          - {{ $t("partner_landing_nl_text2") }}<br/>
          - {{ $t("partner_landing_nl_text3") }}<br/> 
          - {{ $t("partner_landing_nl_text5") }}<br/>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b7_z.jpg">
            <img src="/partner/mediakit/b7.jpg" alt="" class="gl">
          </span>
      </div>

    </div>

  </div>







  <!-- <div class="box" refc="5">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_fe_title") }}</h2>
        <div class="desc">
          {{ $t("partner_landing_fe_desc") }}
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b8_z.jpg">
            <img src="/partner/mediakit/b8.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a target="_blank" href="/">{{ $t("partner_landing_fe_link") }}</a>
          </div>
      </div>

    </div>

  </div> -->




  <div class="box" refc="header_main">

    <div class="lf">
        <h2 class="bttl">Header banner</h2>
        <div class="desc">
          - {{ $t("partner_landing_bb_text1") }}<br/>
          - {{ $t("partner_landing_bb_text2") }}<br/>
          - Fits any screen resolution <br>
          - Direct UTMs <br>
          - Very high visibility <br>
          - Very high conversion <br>
        </div>

        <img src="/partner/mediakit/b18_4.jpg" alt="" class="ex2">

        <div class="seasons">
          <div class="caldr low">
            <div class="mnt">Jan</div> 
          </div>
          <div class="caldr mid">
            <div class="mnt">Feb</div> 
          </div>
          <div class="caldr hig">
            <div class="mnt">Mar</div> 
          </div>
          <div class="caldr hig">
            <div class="mnt">Apr</div> 
          </div>
          <div class="caldr hig">
            <div class="mnt">May</div> 
          </div>
          <div class="caldr mid">
            <div class="mnt">Jun</div> 
          </div>
          <div class="caldr low">
            <div class="mnt">Jul</div> 
          </div>
          <div class="caldr low">
            <div class="mnt">Aug</div> 
          </div>
          <div class="caldr low">
            <div class="mnt">Sep</div> 
          </div>
          <div class="caldr low">
            <div class="mnt">Oct</div> 
          </div>
          <div class="caldr mid">
            <div class="mnt">Nov</div> 
          </div>
          <div class="caldr mid">
            <div class="mnt">Dec</div> 
          </div>
        </div>

        <div class="pscr">
          <span class="low season_ps"></span> {{ $t("partner_landing_bb_subtext1") }}<br/>
          <span class="mid season_ps"></span> {{ $t("partner_landing_bb_subtext2") }}<br/>
          <span class="hig season_ps"></span> {{ $t("partner_landing_bb_subtext3") }}
        </div>

    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b9_z.jpg">
            <img src="/partner/mediakit/b9_2.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a target="_blank" href="/">{{ $t("partner_landing_bb_link") }}</a>
          </div>
      </div>

    </div>

  </div>








 





  <div class="box" refc="sidebar_banner">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_sb_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_sb_text1") }}<br/>
          - {{ $t("partner_landing_sb_text2") }}<br/>
          - {{ $t("partner_landing_sb_text3") }}<br/>
          - {{ $t("partner_landing_sb_text4") }}<br/>
          - Only for contest <br>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b11_z.jpg">
            <img src="/partner/mediakit/b11.jpg" alt="" class="gl">
          </span>
          <div class="lks">
          </div>
      </div>

    </div>

  </div>







  <!--div class="box" refc="9">

    <div class="lf">
        <h2 class="bttl">9. {{ $t("partner_landing_aff_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_aff_text1") }}<br/>
          - {{ $t("partner_landing_aff_text2") }}<br/>
          - {{ $t("partner_landing_aff_text3") }}<br/>
          - {{ $t("partner_landing_aff_text4") }}<br/>
          - {{ $t("partner_landing_aff_text5") }}<br/>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b12_z.jpg">
            <img src="/partner/mediakit/b12.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a target="_blank" href="/diaries/115-economical-cultivation-on-mapito">{{ $t("partner_landing_aff_link") }}</a>
          </div>
      </div>

    </div>

  </div-->





  <!--div class="box" refc="9">

    <div class="lf">
        <h2 class="bttl">
          9. {{ $t("partner_landing_addons_title") }}
        </h2>
        <div class="desc">
          - <span class="discount_region">{{ $t("partner_landing_addons_text1_1") }}</span> {{ $t("partner_landing_addons_text1_2") }}<br/>
          - {{ $t("partner_landing_addons_text2") }}<br/>
          - {{ $t("partner_landing_addons_text3") }}<br/>
          - {{ $t("partner_landing_addons_text4") }}<br/>

        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b17_z.jpg">
            <img src="/partner/mediakit/b21.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div-->




  <div class="box" refc="gd_business">

    <div class="lf">
  

        <h2 class="bttl">{{ $t("partner_landing_b2b_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_b2b_text1") }}<br/>
          - {{ $t("partner_landing_b2b_text2") }}<br/>
          - {{ $t("partner_landing_b2b_text3") }}<br/>
          - {{ $t("partner_landing_b2b_text4") }}<br/>        
          - {{ $t("partner_landing_b2b_text6") }}<br/> 
          - {{ $t("partner_landing_b2b_text8") }}<br/>
          - {{ $t("partner_landing_b2b_text9") }}<br/>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/brand-manager/welcome">
            <img src="/partner/mediakit/b20.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a target="_blank" href="/brand-manager/welcome">{{ $t("partner_landing_b2b_link") }}</a>
          </div>
      </div>

    </div>

  </div>





  <div class="box" refc="ad_free">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_bp_title") }}</h2>
        <div class="desc">        

          Remove other ads from your Brand page! Make your brand stand out and allow users to focus exclusively on your products.
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b17_z.jpg">
            <img src="/partner/mediakit/b17.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>





  <div class="box" refc="media_tab">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_bp_photo_video_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_bp_photo_video_text1") }}<br/>
          - {{ $t("partner_landing_bp_photo_video_text2") }}<br/>
          - {{ $t("partner_landing_bp_photo_video_text3") }}<br/>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b14_z.jpg">
            <img src="/partner/mediakit/b14.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>


 




  <div class="box" refc="article_link">

    <div class="lf">
        <h2 class="bttl">{{ $t("partner_landing_bp_seo_title") }}</h2>
        <div class="desc">
          - {{ $t("partner_landing_bp_seok_text1") }}<br/>
          - {{ $t("partner_landing_bp_seok_text2") }}<br/>
          - {{ $t("partner_landing_bp_seok_text3") }}<br/>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b16_z.jpg">
            <img src="/partner/mediakit/b16.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>



  <div class="box" refc="buy_button">

    <div class="lf">
        <h2 class="bttl">Button Buy</h2>
        <div class="desc"> 
        - Placed on the main page of the brand and on each page of the strain<br>
        - Direct links to your brand’s website: to the catalog and to each strain<br>
        - Additional traffic source<br>
        - No restrictions on the number of strains<br>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b26.jpg">
            <img src="/partner/mediakit/b26.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>


  <div class="box" refc="top_brand">

    <div class="lf">
        <h2 class="bttl">Top brand on breeders page</h2>
        <div class="desc"> 
          - Placed in 1st and 2nd place in the list of brand categories: breeders, nutrients, tent, lights
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b25.jpg">
            <img src="/partner/mediakit/b25.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>


  <div class="box" refc="featured_brand">

    <div class="lf">
        <h2 class="bttl">Featured Brand on main page</h2>
        <div class="desc"> 
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b23.jpg">
            <img src="/partner/mediakit/b23.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>


  <div class="box" refc="box_banner_recommended">

    <div class="lf">
        <h2 class="bttl">Box banner on main page in the recommended</h2>
        <div class="desc"> 
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b10.jpg">
            <img src="/partner/mediakit/b10.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>




  <div class="box" refc="article_link">

    <div class="lf">
        <h2 class="bttl">SEO Article</h2>
        <div class="desc">
        - Place articles in GD Journal <br>
        - Improve SEO <br>
        - Add backlinks <br>
        - Create great content <br>
        </div>
    </div>

    <div class="rg">

      <div class="mg">
          <span class="zm" href="/partner/mediakit/b24.jpg">
            <img src="/partner/mediakit/b24.jpg" alt="" class="gl">
          </span>
          <div class="lks">
            <a href=""></a>
          </div>
      </div>

    </div>

  </div>




  <div class="statbx"  refc="stat">

    <h2 class="bttl">{{ $t("partner_landing_title_4") }}</h2>

    <div class="stats">

      <div class="stat" style="background-image: url('/partner/mediakit/st1.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_1") }}</div>
        <div class="val">7,800,000</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_1") }}</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st2.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_2") }}</div>
        <div class="val">45%</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_2") }}</div>
      </div>
      <div class="stat hidden" style="background-image: url('/partner/mediakit/st3.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_2") }}</div>
        <div class="val">45%</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_2_1") }}</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st4.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_3") }}</div>
        <div class="val">81,000</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_3") }}</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st5.jpg')">
        <div class="httl"></div>
        <div class="val">{{ $t("partner_landing_statistic_val_4") }}</div>
        <div class="unit">
          {{ $t("partner_landing_statistic_sub_title_4_1") }} <br/>
          {{ $t("partner_landing_statistic_sub_title_4_2") }}
        </div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st6.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_5") }}</div>
        <div class="val">{{ $t("partner_landing_statistic_val_5") }}</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_5") }}</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st7.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_6") }}</div>
        <div class="val">160,000</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_6") }}</div>
      </div>
      <div class="stat hidden" style="background-image: url('/partner/mediakit/st8.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_4") }}</div>
        <div class="val">24,000</div>
        <div class="unit">Emails</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st9.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_7_1") }}<br/>{{ $t("partner_landing_statistic_title_7_2") }}</div>
        <div class="val">350,000</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_7") }}</div>
      </div>
      <div class="stat" style="background-image: url('/partner/mediakit/st10.jpg')">
        <div class="httl">{{ $t("partner_landing_statistic_title_8_1") }}<br/>{{ $t("partner_landing_statistic_title_8_2") }}</div>
        <div class="val">63%</div>
        <div class="unit">{{ $t("partner_landing_statistic_sub_title_8") }}</div>
      </div>


    </div>


  </div>





 


</div>

  
</template>


<script setup>


</script>
 


<style scoped>
.mediakit{
  font-weight: bold;
}
.mediakit a{
   font-weight: bold;
   cursor: pointer;
}
   

/* BOXES */





.mediakit .box{

}
.mediakit .box .lf{
  width: 50%;
}
.mediakit .box .bttl{
  font-size: 2rem!important;
}
.mediakit .box .lf .desc{
  margin: 2rem 0;
  line-height: 1.3rem;
}
.mediakit .box .lf .ex{
  max-width: 130px;
  vertical-align: text-top;
}
.mediakit .box .lf .exp1{
  max-width: 160px;
  vertical-align: text-top;
  margin-right: 20px;
  width: calc(50% - 20px);
}
.mediakit .box .lf .exp2{
  max-width: 170px;
  vertical-align: text-top;
  width: calc(50% - 20px);
}
.mediakit .box .lf .ex2{
  max-width: 100%;
}
.mediakit .box .lf .pscr{
  margin-top: 2rem;
  color: gray;
}
.mediakit .box .rg{
  width: 50%;
  /* margin-top: 5rem; */
}
.mediakit .box .rg .mg{
  margin-bottom: 1rem;
  max-height: 400px;
  text-align: center;
  margin-top: 1rem;
}

.mediakit .box .rg .mg .zm{
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}
.mediakit .box .rg .mg .zm .gl{
  width: 100%;
  /* height: 100%; */
}
.mediakit .box .rg .mg.mb .gl{
  width: auto;
  max-height: 400px;
  max-width: fit-content;
}
.mediakit .box .rg .mg .lks{}
.mediakit .box .rg .mg .lks a{
  text-decoration: underline;
}
.mediakit .box{}
.mediakit .box .lf .custom_checks{
  display: flex;
  flex-wrap: wrap;
}
.mediakit .box .lf .custom_checks .ck{
  width: 50%;
  padding: 10px 30px 10px 0px;
  display: flex;
  align-items: center;
}
.mediakit .box .lf .custom_checks .ck .check{
  color: #2c8759;
  line-height: 1rem;
  margin-right: 10px;
}

.mediakit .box .lf .seasons{
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.mediakit .box .lf .seasons .caldr{
  width: calc(25% - 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 3px;
  margin: 0px 10px 10px 0px;
}
.mediakit .box .lf .seasons .caldr.low .mnt{}
.mediakit .box .lf .seasons .caldr.mid .mnt{
  background-color: #af377f;
}
.mediakit .box .lf .seasons .caldr.hig .mnt{
  background-color: #c40011;
}
.mediakit .box .lf .seasons .caldr .mnt{
  background-color: #3897b3;
  width: 100%;
  text-align: center;
  color: white;
  padding: 5px;
  border-radius: 3px 3px 0px 0px;
}
.mediakit .box .lf .seasons .caldr .prc{
  padding: 5px;
}
.mediakit .box{
  display: flex;
  width: 100%;
  max-width: 850px;
  margin: 14rem auto;
  align-items: center;
}


.mediakit .box .lblprice{display: flex;align-items: center;margin-bottom: -1rem;}


.mediakit .box .lblprice .lbl{margin-right: 10px;font-size: 1rem;}

.mediakit .box .lblprice .hnt{color: gray;}

.mediakit .box .discount_region{
color: red;
border: 1px red solid;
display: inline-block;
border-radius: 3px;
padding: 2px 4px;
line-height: 0.9rem;
font-size: 0.9rem;
}

.mediakit .box .season_ps{
border-radius: 20px;
    width: 14px;
    height: 14px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
}

.mediakit .box .season_ps.low{
background-color: #3897b3;
}

.mediakit .box .season_ps.mid{
background-color: #af377f;
}

.mediakit .box .season_ps.hig{
background-color: #c40011;
}


.mediakit .box .disc_volumes{display: flex;flex-direction: row;align-items: flex-start;justify-content: flex-start;}

.mediakit .box .disc_volumes .vol{width: 60px;display: flex;flex-direction: column;margin-right: 6px;text-align: center;}

.mediakit .box .disc_volumes .vol .val{background-color: #2b8759;height: 100px;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;border-radius: 3px;/* padding: 2px; */}

.mediakit .box .disc_volumes .vol .val .disc{background-color: #ffffff8c;width: 100%;text-align: center;font-size: 0.85rem;color: white;/* margin: 1px; */display: flex;border-radius: 3px 3px 0px 0px;flex-direction: column;align-items: center;border-bottom: 1px #ffffff29 solid;justify-content: flex-end;box-shadow: 0px 0px 50px white;}

.mediakit .box .disc_volumes .vol.vol_1{/* width: 60px; *//* display: flex; *//* flex-direction: column; *//* margin-right: 6px; */}
.mediakit .box .disc_volumes .vol.vol_1 .disc{
/* display: none; */
}
.mediakit .box .disc_volumes .vol.vol_2{

}
.mediakit .box .disc_volumes .vol.vol_2 .disc{height: 20px;}
.mediakit .box .disc_volumes .vol.vol_3{

}
.mediakit .box .disc_volumes .vol.vol_3 .disc{height: 30px;}
.mediakit .box .disc_volumes .vol.vol_4{

}
.mediakit .box .disc_volumes .vol.vol_4 .disc{height: 38px;}
.mediakit .box .disc_volumes .vol .name{

}

@media (max-width: 768px) {
.mediakit .box{
  flex-direction: column;
  padding: 0;
}
.mediakit .box .lf{

  width:100%;
}
.mediakit .box .rg{
  width:100%;
  margin-top:1rem;
}
} 

/* STATS */
.statbx {
  display: flex;
  width: 100%;
  max-width: 850px;
  margin: 14rem auto;
  flex-direction: column;
}
.statbx .bttl{
  font-size: 2rem!important;
  margin-bottom: 2rem;
}
.statbx .stats{
  display: flex;
  flex-wrap: wrap;
}
.statbx .stats .stat{
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #00000024;
  width: 30%;
  margin-right: 3%;
  margin-bottom: 30px;
  padding: 20px;
  min-height: 226px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-x: 46px;
  background-position-y: -61px;
}
.statbx .stats .stat .httl{
  font-size: 1.4rem;
  margin-bottom: 1rem;
  line-height: 2rem;
}
.statbx .stats .stat .val{
  color: #2b8759;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: auto;
}
.statbx .stats .stat .unit{
  font-size: 0.8rem;
  color: gray;
  margin-top: 0px;
}

@media (max-width: 768px) {
.statbx{
  padding:0 2rem;

}
.statbx .stats .stat{
  width: 100%;
}
}

</style>